<template>
<section class="Page Faq">
  <!-- eslint-disable max-len -->
  <h2 class="Title Title--type-h2 Page-Title Faq-Title">
    {{ $t('homepage.faq.title') }} <span class="Colored">{{ $t('homepage.faq.titleColored') }}</span>
  </h2>
  <template v-for="section in $t('faq.sections')">
    <div class="Title Title--type-h4 Faq-Subtitle" :key="section.title">
      {{ section.title }}
    </div>
    <Accordion
      class="Faq-Accordion"
      v-for="item in section.children"
      :key="item.title"
      :title="item.title"
      :content="item.content"
    />
  </template>
</section>
</template>

<script>
import Accordion from '@/components/Accordion.vue';

export default {
  name: 'FaqPage',
  components: {
    Accordion,
  },
  metaInfo() {
    return {
      title: this.$t('metaInfo.faq.title'),
      meta: [
        {
          name: 'description',
          content:
            this.$t('metaInfo.faq.description'),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.Faq {
  &-Subtitle {
    margin-top: 33px;
    margin-bottom: 16px;
    color: var(--color-more-faded);

    @media(min-width: $screen-m) {
      margin-top: 40px;
      margin-bottom: 22px;
    }

    @media(min-width: $screen-l) {
      margin-top: 48px;
      margin-bottom: 22px;
    }

    @media(min-width: $screen-xl) {
      margin-top: 58px;
      margin-bottom: 28px;
    }
  }

  &-Accordion {
    margin-bottom: 10px;
  }

  &-Table {
    th, td {
      padding-right: 10px;

      &:last-child {
        padding-right: 0;
      }

      &:nth-child(2), &:nth-child(3) {
        text-align: center;
      }
    }
  }
}

</style>
